// Dependencies
import React from 'react'
import { useInView } from 'react-intersection-observer'
// Hooks
import useSettings from '../hooks/useSettings'
// Components
import Logo from "../components/Logo"
import Link from "../components/Link"
import Lockup from "../components/Lockup"

const Footer = (props) => {

	// Content
	const { footerLinks, footerAbout } = useSettings()
	
	// UX
	const { ref, inView } = useInView({
    threshold: 0.9,
		triggerOnce: true
  });

	// Style
	const style = {
		opacity: inView ? 1 : 0,
		transition: `opacity 0.75s ease-in-out`
	}
	
	return (
		<footer className="prel fv" ref={ref} style={style}>

			<div className="footer__inner fs prel">
				<div className="flex-center fs footer__logo">
					<Link to={props.footerLink || "/"} onClick={() => window.scrollTo(0, 0)}>
						{props.is404 ? (
							<Lockup text="404" />
						) : (
							<Logo />
						)}
					</Link>
				</div>

				<div className="pabs b0 footer__content">
					<div className="footer__left">
						<p className="p2 bookish mbx4 about-width">{footerAbout?.text}</p>
						<div className="">
							{footerLinks?.map((link, index) => (
								<Link to={link.internalLink} href={link.href} className="btn mrx2" key={`link${index}`}>{link.label}</Link>
							))}
						</div>
					</div>

					<div className="footer__right">
						<div className="mtx2">
							{/* <NewsletterForm /> */}
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer