// Dependencies
import React from 'react'
// Hooks
import useIdle from '../hooks/useIdle'
// Components
import Logo from '../components/Logo'

const Lockup = ({ text }) => {
	
	return (
		<div className="lockup">
			<Logo />
			<div>{text}</div>
		</div>
	)
}

export const ScreensaverLogo = ({ text }) => {

	const isIdle = useIdle({
    timeToIdle: 8000
  });

	return (
		isIdle && (
			<div className="pfix fv flex-center screensaver__lockup">
				<Lockup text={text} />
			</div>
		)
	)
}

export default Lockup