import { graphql, useStaticQuery } from "gatsby";

const allSettingsQuery = graphql`
	query allSettingsQuery {
		allContentfulSettings(filter: {contentful_id: {eq: "1NvlpsawwN2IEieW8uTZvy"}}) {
			nodes {
				defaultTitle
				defaultDescription {
					text: defaultDescription
				}
				defaultShareImage {
					file {
						url
					}
					gatsbyImageData(
						layout: CONSTRAINED # or CONSTRAINED
						width: 1200
						formats: [JPG] # default is WEBP
					)
				}
				footerLinks {
					label
					internalLink
					href
				}
				footerAbout {
					text: footerAbout
				}
				cartSmallPrint {
					raw
				}
				backgroundColour
			}
		}
	}
`

export function useSettingsBackgroundColour() {
	const data = useStaticQuery(allSettingsQuery)
  return data.allContentfulSettings.nodes[0].backgroundColour ?? '#F4F0EB'
}

function useSettings() {
	const data = useStaticQuery(allSettingsQuery)
  return data.allContentfulSettings.nodes[0]
}

export default useSettings
