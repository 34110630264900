// Dependencies
import React, { useEffect } from "react"
// Hooks
import { useSettingsBackgroundColour } from "../hooks/useSettings"
// Components
import Meta from "../components/Meta"
import Footer from "../components/Footer"
import CookieNotice from "../components/CookieNotice"
// import Notifications from "../components/Notifications"
// CSS
import "../css/styles.scss"

function useColours(backgroundColor, foregroundColor) {
	const globalColour = useSettingsBackgroundColour()
	useEffect(() => {
		document.documentElement.style.setProperty('--background', backgroundColor || globalColour || '#F4F0EB');
		document.documentElement.style.setProperty('--color', foregroundColor || '#000000');
	}, [backgroundColor, foregroundColor])
}


const Layout = ({ 
	title, 
	description, 
	shareImage,
	backgroundColor, 
	foregroundColor,
	children, 
	contactPage, 
	is404,
	footerLink="/"
}) => {

	useColours(backgroundColor, foregroundColor)

	return (
		<>
			<Meta title={title} description={description} shareImage={shareImage} />
			<main className={contactPage ? 'is-contact' : ''}>{children}</main>
			<Footer is404={is404} footerLink={footerLink} />
			<CookieNotice />
		</>
	)
}

export default Layout