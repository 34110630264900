// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
// Hooks & Helpers
import useSettings from "../hooks/useSettings"
// Components
// ...

const isProduction = process.env.NODE_ENV !== 'development'
// const isProduction = true

function Meta({ description, lang, meta, title, shareImage, css }) {

  const { defaultTitle, defaultDescription, defaultShareImage } = useSettings()

  const metaTitle = title ? `${title} / Song for the Mute` : (defaultTitle || 'Gatsby Starter')
  const metaDescription = description || defaultDescription?.text || 'Lorem ipsum'
  const metaImage = shareImage || (defaultShareImage && `https:${defaultShareImage.file?.url}`) || ``

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
			style={[{
        "cssText": css
      }]}
    >
			<meta name={`og:title`} content={metaTitle} />
			<meta name={`description`} content={metaDescription} />
			<meta name={`og:description`} content={metaDescription} />
			<meta name={`og:image`} content={metaImage} />
			<meta name={`og:type`} content={`website`} />
			<meta name={`twitter:card`} content={`summary`} />
			<meta name={`twitter:creator`} content={`@songforthemute`} />
			<meta name={`twitter:title`} content={metaTitle} />
			<meta name={`twitter:image`} content={metaImage} />
			<meta name={`twitter:description`} content={metaDescription} />

			{/* Pinterest */}
			<meta name="p:domain_verify" content="5fad4b2b9ecdee65b9cf0397de80d678"/>

			{/* Facebook */}
			{isProduction && <meta name="facebook-domain-verification" content="ep9njbh1ole1run7fzmwz6cu37opxv" />}
			{/* {isProduction && (
				<script>
					{`
						!function(f,b,e,v,n,t,s)
						{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
						n.callMethod.apply(n,arguments):n.queue.push(arguments)};
						if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
						n.queue=[];t=b.createElement(e);t.async=!0;
						t.src=v;s=b.getElementsByTagName(e)[0];
						s.parentNode.insertBefore(t,s)}(window, document,'script',
						'https://connect.facebook.net/en_US/fbevents.js');
						fbq('init', '187519611671129');
						fbq('track', 'PageView');
					`}
				</script>
			)}
			{isProduction && (
				<noscript>
					{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=187519611671129&ev=PageView&noscript=1" />`}
				</noscript>
			)} */}

			{/* Google */}
			{isProduction && <meta name="google-site-verification" content="_Cn5PZt_70p15OQsuXxmTLYqo-AODzbZmVLm0GH6BP4" />}
			{isProduction && <meta name="google-site-verification" content="google44380230f7b197e5.html"/>}
			{isProduction && <meta name="google-site-verification" content="d2OKjuq1SdER9LaTpsvrbf-MzfWk7lzIcM85gptuhNw" />}
			{/* {isProduction && <script async src="https://www.googletagmanager.com/gtag/js?id=UA-190449964-1"></script>}			 */}
			{/* {isProduction && (
				<script>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){window.dataLayer && window.dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', 'UA-190449964-1');
						gtag('config', 'AW-405339707');

						gtag('event', 'page_view', {
							'send_to': 'AW-405339707',
							'value': 'replace with value',
							'items': [{
								'id': 'replace with value',
								'google_business_vertical': 'retail'
							}]
						});
					`}
				</script>
			)} */}

			{/* Klaviyo */}
			{isProduction && <script type="text/javascript" src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.GATSBY_KLAVIYO_ID}`} async />}
			{isProduction && <script type="text/javascript" src={`//www.klaviyo.com/media/js/public/klaviyo_subscribe.js`} />}
		</Helmet>
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
	css: ``
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.string
};

export default Meta;
