import React from "react"
import { Link } from "gatsby"
import { useNavigationContext } from "../wrappers/Navigation"

const CookieNotice = () => {

	const { cookiesState } = useNavigationContext()
	const [accepted, setAccepted] = cookiesState

	return (
		!accepted && (
			<div className="cookie-notice has-links">
				<p>
					We use cookies to provide a personalised shopping experience. To find out more please read our <Link to="/info/faqs/privacy">cookie policy</Link>.
				</p>
				<button className="btn" onClick={() => setAccepted(true)}>Accept & close</button>
			</div>
		)
	)
}

export default CookieNotice